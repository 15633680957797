import React from 'react';
import { Badge } from 'reactstrap';

const BADGE_TO_COLOR_MAPPING = {
    tools: {
        textColor: 'white',
        bgColor: '#bb3b0e',
        text: 'TOOLS',
    },
    product: {
        textColor: 'white',
        bgColor: '#dd7631',
        text: 'PRODUCT',
    },
    insight: {
        textColor: 'white',
        bgColor: '#708160',
        text: 'INSIGHT',
    },
    reviews: {
        textColor: 'white',
        bgColor: '#d8c593',
        text: 'REVIEWS',
    },
    art: {
        textColor: 'white',
        bgColor: '#1F8EA3',
        text: 'ART',
    },
};

const TagBadges = (props) => {
    if (!props.tags) return null;
    const tags = props.tags.split(',');
    return tags.map((tag) => {
        const tagMapping = BADGE_TO_COLOR_MAPPING[tag.trim()];
        if (tagMapping) {
            return (
                <React.Fragment key={tag}>
                    <Badge
                        style={{
                            backgroundColor: tagMapping.bgColor,
                            color: tagMapping.textColor,
                        }}
                    >
                        {tagMapping.text}
                    </Badge>
                    &nbsp;
                </React.Fragment>
            );
        }
        return null;
    });
};

export default TagBadges;
