import React from 'react';
import { Row, Col } from 'reactstrap';
import { AwesomeButton } from 'react-awesome-button';

const FrontPageBanner = () => (
    <div className="banner-padding">
        <Row>
            <Col md="4">
                <div className="center">
                    <img
                        src="https://i.gyazo.com/a09bc9897520c84c2820620c4eb59e5d.gif"
                        alt="fragrance calculator"
                    />
                </div>
            </Col>
            <Col md="4">
                <div className="center">
                    <img
                        src="https://i.gyazo.com/8f649aed1e779e4c7a296bfea0668613.gif"
                        alt="Working with 464 Soy Wax"
                    />
                </div>
            </Col>
            <Col md="4">
                <div className="center">
                    <img
                        src="https://i.gyazo.com/08be98770de977e170ce4ce52e01e703.gif"
                        alt="Fragrance pricing"
                    />
                </div>
            </Col>
        </Row>
        <Row>
            <Col md="4">
                <div className="center">
                    <AwesomeButton
                        type="primary"
                        href="/candles/scent-calculator/"
                    >
                        Fragrance calculator
                    </AwesomeButton>
                </div>
            </Col>
            <Col md="4">
                <div className="center">
                    <AwesomeButton
                        href="/candles/optimizing_464_soy_candle/"
                        type="primary"
                    >
                        Working with 464 Soy
                    </AwesomeButton>
                </div>
            </Col>
            <Col md="4">
                <div className="center">
                    <AwesomeButton
                        href="/candles/fragrance-pricing/tool"
                        type="primary"
                    >
                        Fragrance pricing tool
                    </AwesomeButton>
                </div>
            </Col>
        </Row>
    </div>
);

export default FrontPageBanner;
