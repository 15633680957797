import React from 'react'
import {
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Col,
} from 'reactstrap'
import './list_footer.scss'

const StyledPaginationItem = (props) => {
    return (
        <PaginationItem>
            <PaginationLink {...props} id="pagination-link">
                {props.children}
            </PaginationLink>
        </PaginationItem>
    )
}

const ListFooter = (props) => {
    // copied over from this repo:
    // https://github.com/NickyMeuleman/gatsby-paginated-blog/blob/master/src/templates/blog-list.js

    const { currentPage, numPages } = props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
        // <div style={{ position: 'fixed', bottom: '0' }}>
        // the above styles would enforce the footer to be at the bottom of
        // the page; however, this breaks the hover-over. As is is a better
        // experience for the user.
        <div>
            <Row>
                <Col md={3} />
                <Col md={6} style={{ textAlign: 'center' }}>
                    <div style={{ display: 'inline-block' }}>
                        <Pagination aria-label="footer pagination">
                            <StyledPaginationItem
                                first
                                onClick={() => (window.location = '/')}
                                // href="/"
                            />
                            <StyledPaginationItem
                                previous
                                onClick={() => {
                                    const nextLocation =
                                        isFirst || prevPage === '/'
                                            ? '/'
                                            : `/${prevPage}`
                                    window.location = nextLocation
                                }}
                            />
                            {Array.from({ length: numPages }, (_, i) => {
                                if (i === 0) {
                                    return (
                                        <StyledPaginationItem
                                            key={`pagination-number${1}`}
                                            onClick={() =>
                                                (window.location = '/')
                                            }
                                        >
                                            1
                                        </StyledPaginationItem>
                                    )
                                }

                                if (i > numPages) {
                                    return (
                                        <StyledPaginationItem
                                            key={`pagination-number${numPages}`}
                                            onClick={() => {
                                                window.location = `/${numPages}`
                                            }}
                                        >
                                            {numPages}
                                        </StyledPaginationItem>
                                    )
                                }

                                return (
                                    <StyledPaginationItem
                                        key={`pagination-number${i + 1}`}
                                        onClick={() => {
                                            window.location = `/${i + 1}`
                                        }}
                                    >
                                        {i + 1}
                                    </StyledPaginationItem>
                                )
                            })}
                            <StyledPaginationItem
                                next
                                onClick={() => {
                                    window.location = isLast
                                        ? `/${numPages}`
                                        : `/${nextPage}`
                                }}
                            />
                            <StyledPaginationItem
                                last
                                onClick={() => {
                                    window.location = `/${numPages}`
                                }}
                            />
                        </Pagination>
                    </div>
                </Col>
                <Col md={3} />
            </Row>
        </div>
    )
}

export default ListFooter
