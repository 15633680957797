import React from 'react';
import { Link, graphql } from 'gatsby';
import { Col, Row } from 'reactstrap';
import 'react-awesome-button/dist/styles.css';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Font from '../components/font';
import ListFooter from '../components/ListFooter';
import { rhythm } from '../utils/typography';
import LinkStylesheets from '../components/LinkStylesheets';
import TagBadges from '../components/TagBadges';

import 'bootstrap/dist/css/bootstrap.min.css';
import MailChimpJS from '../integrations/mailChimp';
import Base from './base';
import FrontPageBanner from '../components/FrontPageBanner';

const BlogIndex = ({ data, location, pageContext }) => {
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;
    return (
        <Base>
            <Layout location={location} title={siteTitle}>
                <Font />
                <LinkStylesheets />
                <SEO title="Thoughtfulcandles | Candles geared towards men | Aimed to be practical, elegant, and simple" />
                <Bio />
                {pageContext?.currentPage === 1 ? <FrontPageBanner /> : null}
                {posts.map(({ node }) => {
                    const isProd = process.env.NODE_ENV === 'production';
                    if (node.frontmatter.draft === 'true' && isProd) {
                        // this means it's not ready _yet_
                        return null;
                    }

                    const title = node.frontmatter.title || node.fields.slug;
                    const imgSrc = node.frontmatter.imagelocation
                        ? node.frontmatter.imagelocation.publicURL
                        : null;
                    return (
                        <article
                            key={node.fields.slug}
                            style={{
                                marginBottom: rhythm(2),
                            }}
                            className="post animate__fadeIn"
                        >
                            <Row>
                                <Col md="9">
                                    <header>
                                        <div>
                                            <h3
                                                style={{
                                                    marginBottom: rhythm(1 / 4),
                                                }}
                                            >
                                                <Link
                                                    style={{
                                                        boxShadow: `none`,
                                                    }}
                                                    to={node.fields.slug}
                                                    className="post-list-header"
                                                >
                                                    {title}
                                                </Link>
                                                &nbsp; &nbsp;
                                            </h3>
                                        </div>
                                    </header>
                                    <section className="post-description">
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    node.frontmatter
                                                        .description ||
                                                    node.excerpt,
                                            }}
                                        />
                                    </section>
                                    {node.frontmatter.tags ? (
                                        <section>
                                            Tags:{' '}
                                            <TagBadges
                                                tags={node.frontmatter.tags}
                                            />
                                        </section>
                                    ) : null}
                                    <small>
                                        {node.frontmatter.date}
                                        {node.frontmatter.duration ? (
                                            <>
                                                {' '}
                                                | {
                                                    node.frontmatter.duration
                                                }{' '}
                                                read
                                            </>
                                        ) : null}
                                    </small>
                                    {node.frontmatter.author ? (
                                        <p>
                                            <small>
                                                By: {node.frontmatter.author}
                                            </small>
                                        </p>
                                    ) : null}
                                </Col>
                                <Col md="3">
                                    {imgSrc ? (
                                        <img
                                            src={imgSrc}
                                            className="img-post"
                                        />
                                    ) : null}
                                </Col>
                            </Row>
                        </article>
                    );
                })}
                <MailChimpJS />
                <ListFooter pageContext={pageContext} />
            </Layout>
        </Base>
    );
};

export default BlogIndex;

export const prodPageQuery = graphql`
    query prodPageQuery($drafts: [String]!, $skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
            filter: { frontmatter: { draft: { in: $drafts } } }
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        category
                        draft
                        duration
                        author
                        tags
                        imagelocation {
                            publicURL
                            relativePath
                        }
                    }
                }
            }
        }
    }
`;
